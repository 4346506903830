define("balance-ember/controllers/login", ["exports", "balance-ember/utils/urls"], function (_exports, _urls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // `ember-test` won't be available in production
  if (!Ember.Test) {
    Ember.Test = {
      registerWaiter() {}

    };
  }

  const {
    registerWaiter,
    unregisterWaiter
  } = Ember.Test;

  const delayedRedirect = to => {
    setTimeout(() => {
      window.location = to;
    }, 500);
  };

  let LoginController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class LoginController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _initializerDefineProperty(this, "eventTracking", _descriptor2, this);

      _initializerDefineProperty(this, "features", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _defineProperty(this, "queryParams", ['token', 'confirmed']);
    }

    willDestroy() {
      unregisterWaiter(this.waiter);
    }

    sendLink() {
      return this.ajax.request((0, _urls.UrlForMagicLink)(), {
        data: {
          email: this.identification
        },
        method: 'POST'
      }).then(() => {
        Ember.set(this, 'sentMagic', true);
        Ember.set(this, 'errorMessage', null);
      }).catch(({
        _response,
        _jqXHR,
        payload
      }) => {
        Ember.set(this, 'sentMagic', false);
        Ember.set(this, 'isAuthenticating', false);
        Ember.set(this, 'errorMessage', payload.error);

        if (payload.redirect) {
          delayedRedirect(payload.redirect);
        }
      });
    }

    authenticateWithToken(token) {
      Ember.set(this, 'isAuthenticating', true);
      this.session.authenticate('authenticator:magic', token).then(() => {
        this.eventTracking.trackEvent('Successful Magic Login', {
          email: this.session.currentUser.email
        });
      }, async response => {
        Ember.set(this, 'isAuthenticating', false);
        let json = await response.json();
        Ember.set(this, 'errorMessage', json.error);

        if (json.redirect) {
          delayedRedirect(json.redirect);
        } else if (this.errorMessage) {
          return this.eventTracking.trackEvent('Failed Magic Login', {
            error: this.errorMessage,
            attemptedEmail: json.email // This may not be present

          });
        }
      });
    }

    authenticate() {
      // HACK: Need to do this for Budget Acceptance tests. For some reason the `andThen` helpers
      // are not waiting for the promises to resolve after the API requests to the rails app.
      // This is only being seen on tests where we are actually hitting live data. Could not figure
      // out how to make them wait aside from doing what [this article](https://medium.com/@chrisdmasters/testing-async-in-ember-js-part-one-7ec9bc070c0e)
      // suggested.
      Ember.set(this, 'isAuthenticating', true);
      let didAttemptAuthentication = false;
      Ember.set(this, 'waiter', () => didAttemptAuthentication);
      registerWaiter(this.waiter);
      const {
        identification,
        password
      } = this;
      return this.session.authenticate('authenticator:devise', identification, password).then(() => {
        this.eventTracking.trackEvent('Successful Login', {
          email: identification
        });
      }, async response => {
        Ember.set(this, 'isAuthenticating', false);
        let json = await response.json();
        document.querySelector('input[type="password"]').value = '';
        document.querySelector('input[type="password"]').focus();
        Ember.set(this, 'errorMessage', json.error);
        Ember.set(this, 'confirmMessage', json.show_send_confirmation_link);
        Ember.set(this, 'unlockMessage', json.show_send_unlock_link);

        if (json.redirect) {
          delayedRedirect(json.redirect);
        } else if (this.errorMessage) {
          return this.eventTracking.trackEvent('Failed Login', {
            error: this.errorMessage,
            attemptedEmail: this.identification
          });
        }
      }).finally(() => {
        didAttemptAuthentication = true;
      });
    }

    get passwordFieldEnabled() {
      return !this.features.isEnabled('hidePasswordLogin');
    }

    get passwordResetLinkEnabled() {
      return !this.features.isEnabled('hidePasswordLogin') && !this.features.isEnabled('ldap');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "eventTracking", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sendLink", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "sendLink"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "authenticateWithToken", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "authenticateWithToken"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "authenticate", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "authenticate"), _class.prototype)), _class));
  _exports.default = LoginController;
});